import {Typography} from "@mui/material";
import {CheckCircle} from "@mui/icons-material";
import qr from '../../../assets/img/qr.png';
import invitation from '../../../assets/img/invitation.png';

export const FeaturesComponent = () => {
    const features = [
        {id: 1, title: 'Cero listas', description: 'Ya no tendrás que esperar que busquen en una tediosa lista a las personas que quieran visitar tu residencia'},
        {id: 2, title: 'Sin llamadas', description: 'Se terminaron las molestas llamadas para autorizar las visitas y el tiempo de espera con la seguridad mientras verifican sus datos.'},
        {id: 3, title: 'Invitación unica', description: 'Podras generar invitaciones unicas a las personas que quieras que ingresen a tu residencia.'},
        {id: 4, title: 'Rangos de tiempo', description: 'Controlaras y verificaras el rango de horas autorizadas para el ingreso a tu residencia'},
    ]

    return (
        <div className={'container-features'}>
            <div className="mobiles">
                <img className={'qr'} src={qr} alt=""/>
                <img className={'invitation'} src={invitation} alt=""/>
            </div>
            <div className="features">
                <Typography className="title">
                    Caracteristicas
                </Typography>
                <div className="features-content">
                    {
                        features.map(ele => {
                            return (
                                <div key={ele.id} className={'feature'}>
                                    <Typography className="title">
                                        <CheckCircle sx={{fontSize: 20, color: '#0F1F3C', marginTop: '5px'}}/>
                                        {ele.title}
                                    </Typography>
                                    <Typography className="description">
                                        {ele.description}
                                    </Typography>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}