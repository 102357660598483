import { createTheme } from "@mui/material";
import styles from './../styles/style.module.scss';

const cssVar = (name: string) =>
    getComputedStyle(document.documentElement).getPropertyValue(name).trim();

export let theme = createTheme({
    palette: {
        primary: {
            main: cssVar('--primary-color'),
        },
        secondary: {
            main: cssVar('--secondary-color'),
        },
        background: {
            default: cssVar('--background-color')
        },
    }
})

theme = createTheme(theme, {
    palette: {
        info: {
            main: styles.colorInfo
        },
        danger: {
            main: styles.colorDanger
        },
        success: {
            main: styles.colorSuccess
        },
        warning: {
            main: styles.colorWarning
        }
    },
    typography: {
        fontFamily: [
            'Square721 BT', '-apple-system', 'BlinkMacSystemFont', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'
        ].join(','),
        fontsize: 12,
    }
})