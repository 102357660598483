import {useEffect, useState} from "react";
import {ThemeProvider, Typography} from "@mui/material";
import {PresentationComponent} from "../components/Presentation/PresentationComponent";
import {theme} from "../../theme/theme";
import logo from '../../assets/img/images/imagotipo-morado.png'
import frame1 from '../../assets/img/frame1.png';
import frame2 from '../../assets/img/frame2.png';
import frame3 from '../../assets/img/frame3.png';
import {InformationComponent} from "../components/Information/InformationComponent";
import {FeaturesComponent} from "../components/Features/FeaturesComponent";
import {CheckCircle, EmailOutlined, PhoneAndroid, PhoneAndroidOutlined} from "@mui/icons-material";

const HomePage = () => {
    const [scrollEnd, setScrollEnd] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (
                window.innerHeight + document.documentElement.scrollTop ===
                document.documentElement.offsetHeight
            ) {
                setScrollEnd(true);
            } else {
                setScrollEnd(false);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);


    return (
        <ThemeProvider theme={theme}>
            <div className={'main_container'}>
                <div className="right-corner">
                    <img src={frame1} alt=""/>
                </div>
                <div className="frame-left">
                    <img src={frame2} alt=""/>
                </div>
                <div className="frame-right">
                    <img src={frame3} alt=""/>
                </div>
                <header>
                    <div className="logo">
                        <img src={logo} alt=""/>
                    </div>
                </header>
                <section className={'presentation'}>
                    <PresentationComponent/>
                </section>
                <section className={'info'}>
                    <InformationComponent/>
                </section>
                <section className={'features'}>
                    <FeaturesComponent/>
                </section>
                {/*<section className={'contact'}>*/}
                {/*    Contactos*/}
                {/*</section>*/}

            </div>
            <footer className={`animate__animated ${scrollEnd ? 'animate__fadeInUp' : 'animate__fadeOutDown'} animate__faster`}>
                <div
                    className={`content `}>
                    <div className="about">
                        <Typography className="title">
                            Sobre nosotros
                        </Typography>
                        <Typography className="description">
                            Somos una empresa que desarrolla Panal, una aplicación que permite gestionar el
                            acceso a diferentes espacios y recursos usando tu teléfono móvil y el código QR. Nuestro
                            objetivo es mejorar la seguridad y la comodidad de nuestros clientes con una solución
                            flexible y personalizable.
                        </Typography>
                    </div>
                    <div className="contact">
                        <div className="phone">
                            <PhoneAndroidOutlined sx={{fontSize: 30, marginRight: '10px'}}/>
                            (+58) 412-503.17.85
                        </div>
                        <div className="mail">
                            <EmailOutlined sx={{fontSize: 30, marginRight: '10px'}}/>
                            contact@panalcommunity.com
                        </div>
                    </div>
                </div>
            </footer>
        </ThemeProvider>
    )
}
export default HomePage;