import {Button, Typography} from "@mui/material";
import login from '../../../assets/img/login.png';
import name from '../../../assets/img/images/logotipo-panal-morado.png';

export const PresentationComponent = () => {
    return (
        <div className={'container'}>
            <div className="description">
                <Typography className="title">
                    <img src={name} alt=""/>
                </Typography>
                <Typography className="descrip">
                    Bienvenido al futuro de la seguridad y el control de accesos. nuestra innovadora aplicación ha sido
                    diseñada pensando en condominios, residencias, clubes y eventos, con el objetivo de brindar una
                    solución integral y eficiente para proteger y gestionar de manera efectiva el acceso a tus
                    instalaciones
                </Typography>
                {/*<div className="buttons">*/}
                {/*    <Button variant='contained' sx={{fontSize: 20}}>Registrate</Button>*/}
                {/*</div>*/}
            </div>
            <div className="cel">
                <img src={login} alt=""/>
            </div>
        </div>
    )
}