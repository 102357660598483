import {FactCheckOutlined, GppGoodOutlined, InfoOutlined, PaidOutlined} from "@mui/icons-material";
import {Typography} from "@mui/material";

export const InformationComponent = () => {
    const info = [
        {
            id: 1,
            icon: <GppGoodOutlined sx={{fontSize: 80, marginTop: 3}}/>,
            title: 'Seguridad',
            resumen: 'Obten seguridad para tu comunidad',
            description: 'Nuestra app generara un código QR, el cual será una invitación única con la cual las personas podrán ingresar a tu residencia una vez verificado por la app Panal de seguridad.'
        },
        {
            id: 2,
            icon: <InfoOutlined sx={{fontSize: 80, marginTop: 3}}/>,
            title: 'Información',
            resumen: 'La información de sus invitados será confidencial',
            description: 'Nuestra app se asegura que la información de los residentes y sus invitados este segura y confidencial para personas indeseables.'
        },
        {
            id: 3,
            icon: <FactCheckOutlined sx={{fontSize: 80, marginTop: 3}}/>,
            title: 'Eficiencia',
            resumen: 'Permita el acceso mas rapido y eficientemente',
            description: 'Nuestra app permite un acceso rapido, solo debes escanear el codigo y el personal podra ver si la invitación es valida o no; solo toma 5 seg el proceso.'
        },
    ]

    return (
        <div className={'container-info'}>
            <div className="title-section">
                ¿Por que nosotros?
            </div>
            <div className="cards-container">
                {
                    info.map(element => {
                        return (
                            <>
                                <div key={element.id} className="card-info">
                                    <div className="icon">
                                        {element.icon}
                                    </div>
                                    <Typography className="title">
                                        {element.title}
                                    </Typography>
                                    <Typography className="resumen">
                                        {element.resumen}
                                    </Typography>
                                    <Typography className="description">
                                        {element.description}
                                    </Typography>
                                </div>
                            </>
                        )
                    })
                }
            </div>
        </div>
    )
}