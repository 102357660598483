import React from 'react';
import logo from '../../logo.svg';
import './../../styles/styles.scss';
import HomePage from "../../pages/Home/HomePage";

function App() {
  return (
    <div className="App">
      <HomePage />
    </div>
  );
}

export default App;
